<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="dialogCancel" max-width="600px">
      <v-card>
        <v-card-title
          style="font-family: poppinsregular; font-size: 15px; color: #000"
          >Are you sure you want to cancel this order?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text small @click="dialogCancel = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text small @click="cancelOrder()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs11 sm11 pt-5>
        <v-layout wrap>
          <v-flex xs12 sm12 md12 text-left>
            <span
              style="
                color: #1f1f1f;
                font-family: poppinssemibold;
                font-size: 18px;
              "
              >Order Details</span
            >
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center py-2>
          <v-flex xs12 py-4>
            <v-card>
              <v-layout wrap justify-start py-3 px-10>
                <v-flex xs12 py-2>
                  <v-layout wrap justify-space-between>
                    <v-flex xs12 sm6 md3 lg3 text-left>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Order Number
                          </span>
                        </v-flex>
                        <v-flex xs12 v-if="orders.orderId">
                          <span class="orderText">{{ orders.orderId }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 md3 lg3>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Order Date
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <span class="orderText"
                            >{{ formatDate(orders.create_date) }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      md2
                      lg3
                      text-right
                      v-if="
                        orders.orderStatus != 'Cancelled' &&
                        orders.orderStatus != 'Delivered'
                      "
                    >
                      <v-layout wrap justify-end>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Order Cancel
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <v-btn
                            depressed
                            color="red"
                            dark
                            @click="dialogCancel = true"
                            ><span style="font-family: poppinsmedium"
                              >Cancel</span
                            ></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 md2 lg3 text-right>
                      <v-layout wrap justify-end>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Change Status
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <span v-if="orders.orderStatus != 'Cancelled'">
                            <v-btn
                              depressed
                              color="#30b868"
                              dark
                              @click="changeStatus()"
                              ><span style="font-family: poppinsmedium">{{
                                orders.orderStatus
                              }}</span></v-btn
                            >
                          </span>
                          <span
                            v-else
                            style="color: #30b868; font-family: poppinsmedium"
                            >{{ orders.orderStatus }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 py-6>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap v-if="orders.deliveryAddress">
                    <v-flex xs12 sm6 md4 text-left>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span class="orderHeading">Customer Details</span>
                        </v-flex>
                        <v-flex xs12 pt-2>
                          <span
                            style="
                              color: #7e8f85;
                              font-family: poppinsregular;
                              font-size: 14px;
                            "
                            >Name :
                          </span>
                          <span class="orderText">{{
                            orders.deliveryAddress.name
                          }}</span>
                        </v-flex>
                        <v-flex xs12>
                          <span
                            style="
                              color: #7e8f85;
                              font-family: poppinsregular;
                              font-size: 14px;
                            "
                            >Phone :
                          </span>
                          <span class="orderText">{{
                            orders.deliveryAddress.phone
                          }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 md4>
                      <v-layout
                        wrap
                        justify-center
                        v-if="orders.accountDetails"
                      >
                        <v-flex xs12 sm8 text-center>
                          <span class="orderHeading">Refund Bank Details</span>
                        </v-flex>
                        <v-flex xs12 pt-2>
                          <span
                            style="
                              color: #7e8f85;
                              font-family: poppinsregular;
                              font-size: 14px;
                            "
                            >Account Holder Name :
                          </span>
                          <span class="orderText">{{
                            orders.accountDetails.accountHolderName
                          }}</span>
                        </v-flex>
                        <v-flex xs12 pt-2>
                          <span
                            style="
                              color: #7e8f85;
                              font-family: poppinsregular;
                              font-size: 14px;
                            "
                            >Bank Name :
                          </span>
                          <span class="orderText">{{
                            orders.accountDetails.bankName
                          }}</span>
                        </v-flex>
                        <v-flex xs12 pt-2>
                          <span
                            style="
                              color: #7e8f85;
                              font-family: poppinsregular;
                              font-size: 14px;
                            "
                            >Account Number:
                          </span>
                          <span class="orderText">{{
                            orders.accountDetails.accountNo
                          }}</span>
                        </v-flex>
                        <v-flex xs12 pt-2>
                          <span
                            style="
                              color: #7e8f85;
                              font-family: poppinsregular;
                              font-size: 14px;
                            "
                            >IFSC Code:
                          </span>
                          <span class="orderText">{{
                            orders.accountDetails.ifscCode
                          }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 md4>
                      <v-layout wrap justify-end>
                        <v-flex xs12 sm8 text-left>
                          <span class="orderHeading">Shipping Address</span>
                        </v-flex>
                        <v-flex xs12 sm8 pt-2 text-left>
                          <span class="orderText"
                            >{{ orders.deliveryAddress.address }},
                            {{ orders.deliveryAddress.city }},
                            {{ orders.deliveryAddress.district }},
                            {{ orders.deliveryAddress.country }},
                            {{ orders.deliveryAddress.pincode }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 py-6>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 text-left pb-4>
                  <span class="orderHeading">Products </span>
                </v-flex>
                <v-flex xs12 py-2 v-for="(item, i) in products" :key="i">
                  <v-layout wrap justify-start>
                    <v-flex xs2 text-left v-if="item.productId">
                      <v-card outlined>
                        <v-layout wrap>
                          <v-flex xs12 py-5>
                            <v-img
                              :src="mediaUURL + item.productId.photos[0]"
                              height="100px"
                              contain
                              :alt="item.name"
                            >
                              <template v-slot:placeholder>
                                <ImageLoader />
                              </template>
                            </v-img>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs9 pl-2>
                      <v-layout wrap justify-center fill-height>
                        <v-flex xs12 sm12 md12 align-self-center text-left>
                          <v-layout
                            wrap
                            justify-start
                            style="line-height: 16px"
                            fill-height
                            v-if="item.productId"
                          >
                            <v-flex xs12>
                              <router-link
                                :to="'/productDetails?id=' + item.itemId._id"
                              >
                                <span
                                  style="
                                    font-family: poppinssemibold;
                                    font-size: 15px;
                                    color: #191919;
                                  "
                                >
                                  {{ item.productId.productname }}
                                  <!-- <span v-if="orders.name.length > 25"> ... </span> -->
                                </span>
                              </router-link>
                            </v-flex>
                            <v-flex xs12 sm6 v-if="item.itemId">
                              <v-layout wrap>
                                <v-flex xs12 pt-3>
                                  <span
                                    style="
                                      color: #191919;
                                      font-size: 13px;
                                      font-family: poppinsmedium;
                                    "
                                    >Size
                                    <span style="font-family: poppinsregular"
                                      >: {{ item.itemId.size }}</span
                                    >
                                  </span>
                                </v-flex>
                                <v-flex xs12 pt-1>
                                  <span
                                    style="
                                      color: #191919;
                                      font-size: 13px;
                                      font-family: poppinsmedium;
                                    "
                                    >OTY
                                    <span style="font-family: poppinsregular"
                                      >: {{ item.quantity }}
                                    </span>
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex xs12 sm6 pt-2 text-right v-if="item.itemId">
                              <v-layout wrap>
                                <v-flex xs12 pt-1 v-if="item.taxPercentage > 0">
                                  <span
                                    style="
                                      color: #191919;
                                      font-size: 13px;
                                      font-family: poppinsmedium;
                                    "
                                    >Taxable Amount
                                    <span style="font-family: poppinsregular"
                                      >: {{ item.taxableAmount.toFixed(2) }}
                                    </span>
                                  </span>
                                </v-flex>
                                <v-flex xs12 pt-1 v-if="item.taxPercentage > 0">
                                  <span
                                    style="
                                      color: #191919;
                                      font-size: 13px;
                                      font-family: poppinsmedium;
                                    "
                                    >Tax Amount
                                    <span style="font-family: poppinsregular"
                                      >: {{ item.taxAmount.toFixed(2) }}
                                    </span>
                                  </span>
                                </v-flex>
                                <v-flex xs12>
                                  <span
                                    style="
                                      color: #191919;
                                      font-size: 13px;
                                      font-family: poppinsmedium;
                                    "
                                    >Total Amount
                                    <span style="font-family: poppinssemibold"
                                      >: ₹
                                      {{
                                        item.quantity * item.itemId.offerPrice
                                      }}
                                    </span>
                                  </span>
                                  <!-- <span
                                    style="
                                      color: #383838;
                                      font-family: poppinssemibold;
                                    "
                                    > {{ item.quantity }} x ₹ {{ item.itemId.offerPrice }}</span
                                  > -->
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 text-left py-4>
                  <span class="orderHeading">Order Details </span>
                </v-flex>
                <v-flex xs12 pb-4>
                  <v-card flat color="#F3F3F3">
                    <v-layout wrap pa-5>
                      <v-flex xs12 v-if="orders.igstTotal > 0">
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span class="orderText">Total Taxable Amount</span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span class="orderText"
                              >₹ {{ orders.taxableAmount.toFixed(2) }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pt-1 v-if="orders.igstTotal > 0">
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span class="orderText">Total Tax</span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span class="orderText"
                              >₹ {{ orders.igstTotal.toFixed(2) }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span class="orderText">Price</span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span class="orderText"
                              >₹ {{ orders.totalAmount }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pt-1 v-if="orders.isPromoCodeApplied">
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span class="orderText">Promo Code</span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span class="orderText"
                              >₹ {{ orders.couponDiscount }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pt-1>
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span class="orderText">Delivery Charges</span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span class="orderText"
                              >₹ {{ orders.deliveryCharge }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <!-- <v-flex xs12 pt-1>
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span class="orderText">Total tax</span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span class="orderText"
                              >₹ {{ orders.deliveryCharge }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex> -->
                      <v-flex xs12 py-4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span
                              style="
                                color: #000000;
                                font-family: poppinssemibold;
                              "
                              >Total Amount
                            </span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span
                              style="
                                color: #000000;
                                font-family: poppinssemibold;
                              "
                              >₹ {{ orders.subTotal }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs12 text-right>
                  <a
                    target="_blank"
                    :href="ipURL + '/admin/purchase/recipt?id=' + orders._id"
                    class="downlink"
                  >
                    <span
                      style="
                        color: #30b868;
                        font-family: poppinsregular;
                        font-size: 13px;
                        text-decoration: underline;
                      "
                    >
                      <v-icon small color="#30B868"
                        >mdi-download-outline</v-icon
                      >
                      DOWNLOAD INVOICE</span
                    >
                  </a>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      dialogCancel: false,
      timeout: 5000,
      msg: null,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 16,
      searchKey: null,
      pincode: null,
      orders: {},
      products: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/admin/seller/purchaseView",
        data: {
          id: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.orders = response.data.data;
          this.products = response.data.items;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    cancelOrder() {
      axios({
        method: "POST",
        url: "/admin/purchase/cancel",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.statusLoading = false;
          if (response.data.status) {
            this.dialogCancel = false;
            this.msg = "Order Status Changed";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.statusLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changeStatus() {
      axios({
        method: "POST",
        url: "/admin/purchase/statusChange",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.statusLoading = false;
          if (response.data.status) {
            this.msg = "Order Status Changed";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.statusLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>
<style>
.orderHeading {
  color: #000000;
  font-family: poppinsmedium;
  text-transform: uppercase;
}

.orderText {
  color: #000000;
  font-family: poppinsregular;
  font-size: 14px;
}
</style>